import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
    <NewsArticle
        title="Changes to our mortgage products"
        description="With effect from Friday 11 March 2022 we will be making the following changes to our mortgage product range"
    >
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Friday 11 March 2022 we will be making the following changes to our mortgage product range:</p>
    <p><strong>Summary of changes</strong></p>
    <p><strong>Residential Existing Customer Switching Range</strong></p>
    <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p><strong>Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p><strong>Residential Remortgage Range</strong></p>
    <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased </li>
        <li>3 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>3 Year Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
    </ul>
    <p><strong>Residential First Time Buyer Range</strong></p>
    <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p><strong>Residential Home Mover Range</strong></p>
    <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
    <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
    <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p><strong>UK Buy to Let Remortgage Range</strong></p>
    <ul>
        <li>2 Year Fixed Standard at 60% LTV has increased</li>
        <li>5 Year Fixed Standard at 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p><strong>UK Buy to Let Purchase Range</strong></p>
    <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p>All of our fixed rate end dates will be extended as below:</p>
    <ul>
        <li>2 year fixed rates – 31 July 2024</li>
        <li>3 year fixed rates – 31 July 2025</li>
        <li>5 year fixed rates – 31 July 2027</li>
    </ul>
    <p>An updated mortgage rate sheet will be published on Friday 11 March 2022 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
   </NewsArticle>
)

export default Article
